import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import {
  A2HSPWA,
  BillingPlans,
  AdminPWA,
  ContentURLPWA,
  GeoNode,
  IPwaCachePaths,
  IReportClickSubscriberResponse,
  IReportDeepResponse,
  IReportDeviceResponse,
  IReportGeoResponse,
  IReportSubscribersResponse,
  OnBoardingPWA,
  OverviewPWA,
  PushNotificationPWA,
  ReportGeoJSON,
  ResponseBillingPlans,
  TargetingPWA,
  TargetingPWAResponse,
  VerifyResponse,
  IReportSubscribersPromptResponse,
} from './pwa.model';
import { FileDownloadService } from '@core/services/file-download.service';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Site } from '@main/site/site.model';
import { DATE_FNS_FORMAT } from '@core/models/date.model';
import { AuthService } from '@core/services/auth.service';
import { PermissionService } from '@core/services/permission.service';
import { DateTimeFnsService } from '@core/services/date-time-fns.service';
import { format } from 'date-fns';

@Injectable()
export class PWAService {
  public siteDetails: BehaviorSubject<Site> = new BehaviorSubject(null);
  public isShowFixedA2HS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isShowStickyA2HS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    public http: HttpClient,
    public fileDownloadService: FileDownloadService,
    public authService: AuthService,
    public permissionService: PermissionService,
    private readonly dateTimeFnsService: DateTimeFnsService
  ) {}

  public get allowPwa(): boolean {
    const user = this.authService.user.value;
    return this.authService.user && user && (user.is_pwa_admin || user.is_superuser || false);
  }

  public get authUserValue() {
    return this.authService.user.value;
  }

  public get isPublisher(): boolean {
    return this.permissionService.hasScopes(['publisher|admin', 'publisher|basic']);
  }

  public getOverview(siteId: number, dayFilter: string): Observable<OverviewPWA> {
    if (!dayFilter) {
      const [start, end] = this.dateTimeFnsService.dateRanges['Last 30 Days'].map((time) => format(time, DATE_FNS_FORMAT));
      dayFilter = `?start_date=${start}&end_date=${end}`;
    }
    const url = `/pwa/overview/${siteId}/${dayFilter}`;
    return this.http.get<OverviewPWA>(AppService.getAppUri(url));
  }

  public getAdmin(sideId: number): Observable<AdminPWA> {
    const url = `/pwa/admin/${sideId}/`;
    return this.http.get<AdminPWA>(AppService.getAppUri(url));
  }

  public getPushNotification(siteID: number, notificationID: number): Observable<PushNotificationPWA> {
    const url = `/pwa/overview/${siteID}/push_notification/${notificationID}/`;
    return this.http.get<PushNotificationPWA>(AppService.getAppUri(url));
  }

  public createPushNotification(siteID: number, data: FormData): Observable<PushNotificationPWA> {
    const url = `/pwa/overview/${siteID}/push_notification/`;
    return this.http.post<PushNotificationPWA>(AppService.getAppUri(url), data);
  }

  public updatePushNotification(siteID: number, notificationID: number, data: FormData): Observable<PushNotificationPWA> {
    const url = `/pwa/overview/${siteID}/push_notification/${notificationID}/`;
    return this.http.patch<PushNotificationPWA>(AppService.getAppUri(url), data);
  }

  public deletePushNotification(siteID: number, notificationID: number): Observable<PushNotificationPWA> {
    const url = `/pwa/overview/${siteID}/push_notification/${notificationID}/`;
    return this.http.delete<PushNotificationPWA>(AppService.getAppUri(url));
  }

  public getOnboarding(type: string, sideId: number): Observable<OnBoardingPWA> {
    const url = `/pwa/${type}/${sideId}/`;
    return this.http.get<OnBoardingPWA>(AppService.getAppUri(url));
  }

  public updateAdmin(admin: FormData, id: number): Observable<AdminPWA> {
    const url = `/pwa/admin/${id}/`;
    return this.http.put<AdminPWA>(AppService.getAppUri(url), admin);
  }

  public saveAdmin(admin: FormData): Observable<AdminPWA> {
    const url = `/pwa/admin/`;
    return this.http.post<AdminPWA>(AppService.getAppUri(url), admin);
  }

  public async downloadManifest(id: number) {
    const url = `/pwa/manifest/${id}/download/`;
    return this.fileDownloadService.asyncDownload(AppService.getAppUri(url));
  }

  public updateManifest(id: number, data: FormData) {
    const url = `/pwa/manifest/${id}/`;
    return this.http.put(AppService.getAppUri(url), data);
  }

  public saveManifest(data: FormData): Observable<OnBoardingPWA> {
    const url = `/pwa/manifest/`;
    return this.http.post<OnBoardingPWA>(AppService.getAppUri(url), data);
  }

  public getManifest(id: number): Observable<OnBoardingPWA> {
    const url = `/pwa/manifest/${id}/`;
    return this.http.get<OnBoardingPWA>(AppService.getAppUri(url));
  }

  public async downloadSW(id: number) {
    const url = `/pwa/overview/${id}/sw_download/`;
    return this.fileDownloadService.asyncDownload(AppService.getAppUri(url));
  }

  public getPushNotifications(id: number, params: any): Observable<PushNotificationPWA[]> {
    const url = `/pwa/overview/${id}/push_notification/`;
    const query = {
      ...params,
    };
    return this.http.get<PushNotificationPWA[]>(AppService.getAppUri(url), { params: query });
  }

  public verifyOnboarding(url: string): Observable<VerifyResponse> {
    return this.http.get<VerifyResponse>(AppService.getAppUri(url));
  }

  public getCachePathList(siteID: number): Observable<IPwaCachePaths> {
    return this.http.get<IPwaCachePaths>(AppService.getAppUri(`/pwa/overview/${siteID}/cache_path/`));
  }

  public saveCachePaths(siteID: number, data: IPwaCachePaths): Observable<IPwaCachePaths> {
    return this.http.post<IPwaCachePaths>(AppService.getAppUri(`/pwa/overview/${siteID}/cache_path/`), data);
  }

  public saveServiceWorkerFileName(siteID: number, data: Partial<Site>): Observable<Partial<Site>> {
    const uri = `/pwa/service_worker/${siteID}/`;
    return this.http.patch<Partial<Site>>(AppService.getAppUri(uri), data);
  }

  public getTargeting(siteID: number, params: any): Observable<TargetingPWAResponse> {
    const url = `/pwa/overview/${siteID}/target/?site=${siteID}`;
    return this.http.get<TargetingPWAResponse>(AppService.getAppUri(url), { params });
  }

  public createTarget(data: TargetingPWA): Observable<TargetingPWA> {
    const url = `/pwa/overview/${data?.site?.id}/target/`;
    return this.http.post<TargetingPWA>(AppService.getAppUri(url), data);
  }

  public updateTarget(targetId: number, data: TargetingPWA): Observable<TargetingPWA> {
    const url = `/pwa/overview/${data?.site?.id}/target/${targetId}/`;
    return this.http.put<TargetingPWA>(AppService.getAppUri(url), data);
  }

  public getCountries(siteID: number): Observable<GeoNode[]> {
    const url = `/pwa/overview/${siteID}/pwa_countries/`;
    return this.http.get<GeoNode[]>(AppService.getAppUri(url));
  }

  public deleteTarget(targetId: number, siteID: number): Observable<TargetingPWA> {
    const url = `/pwa/overview/${siteID}/target/${targetId}/`;
    return this.http.delete<TargetingPWA>(AppService.getAppUri(url));
  }

  public getTarget(targetId: number, siteID: number): Observable<TargetingPWA> {
    const url = `/pwa/overview/${siteID}/target/${targetId}/`;
    return this.http.get<TargetingPWA>(AppService.getAppUri(url));
  }

  public getReportGeo(siteID: number, params?: any): Observable<IReportGeoResponse> {
    return this.http.get<IReportGeoResponse>(AppService.getAppUri(`/site/site/${siteID}/report_geo/`), { params });
  }

  public getReportGeoMap(siteID: number, params?: any): Observable<ReportGeoJSON> {
    return this.http.get<ReportGeoJSON>(AppService.getAppUri(`/pwa/overview/${siteID}/report_geo_map/`), { params });
  }

  public getReportDevice(siteID: number, dayFilter: string): Observable<IReportDeviceResponse> {
    if (!dayFilter) {
      const [start, end] = this.dateTimeFnsService.dateRanges['Last 30 Days'].map((time) => format(time, DATE_FNS_FORMAT));
      dayFilter = `?start_date=${start}&end_date=${end}`;
    }
    return this.http.get<IReportDeviceResponse>(AppService.getAppUri(`/pwa/overview/${siteID}/report_device/${dayFilter}`));
  }

  public getReportClicks(siteID: number): Observable<IReportClickSubscriberResponse> {
    return this.http.get<IReportClickSubscriberResponse>(AppService.getAppUri(`/pwa/overview/${siteID}/report_clicks/`));
  }

  public getReportSubcriber(siteID: number): Observable<IReportClickSubscriberResponse> {
    return this.http.get<IReportClickSubscriberResponse>(
      AppService.getAppUri(`/pwa/overview/${siteID}/report_subscriber_clicked_in_the_past/`)
    );
  }

  public getReportSubcribersChart(siteID: number, dayFilter: string): Observable<IReportSubscribersResponse> {
    if (!dayFilter) {
      const [start, end] = this.dateTimeFnsService.dateRanges['Last 30 Days'].map((time) => format(time, DATE_FNS_FORMAT));
      dayFilter = `?start_date=${start}&end_date=${end}`;
    }
    return this.http.get<IReportSubscribersResponse>(AppService.getAppUri(`/pwa/overview/${siteID}/report_subscribers/${dayFilter}`));
  }

  public getReportA2HSChart(siteID: number, dayFilter: string): Observable<IReportSubscribersResponse> {
    if (!dayFilter) {
      const [start, end] = this.dateTimeFnsService.dateRanges['Last 30 Days'].map((time) => format(time, DATE_FNS_FORMAT));
      dayFilter = `?start_date=${start}&end_date=${end}`;
    }
    return this.http.get<IReportSubscribersResponse>(AppService.getAppUri(`/pwa/overview/${siteID}/a2hs_report/${dayFilter}`));
  }

  public getReportPromptChart(siteID: number, dayFilter: string): Observable<IReportSubscribersPromptResponse> {
    if (!dayFilter) {
      const [start, end] = this.dateTimeFnsService.dateRanges['Last 30 Days'].map((time) => format(time, DATE_FNS_FORMAT));
      dayFilter = `?start_date=${start}&end_date=${end}`;
    }
    return this.http.get<IReportSubscribersPromptResponse>(
      AppService.getAppUri(`/pwa/overview/${siteID}/prompt_format_report/${dayFilter}`)
    );
  }

  public getGaReportData(siteId: number, dayFilter: string, metrics: string): Observable<any> {
    const params = {
      report_type: 'web_report',
      filters: JSON.stringify([
        {
          key: 'site_app_ids',
          operator: 'IN',
          value: siteId.toString(),
        },
      ]),
      display: 'list',
      date_range: 'custom',
      order_by: 'date',
      dimension: 'date',
      metric: metrics,
    };
    if (!dayFilter) {
      const [start, end] = this.dateTimeFnsService.dateRanges['Last 30 Days'].map((time) => format(time, DATE_FNS_FORMAT));
      dayFilter = `?start_date=${start}&end_date=${end}`;
    }
    return this.http.get<any>(AppService.getAppUri(`/report/report/${dayFilter}`), { params });
  }

  public getReportDeepData(siteId: number, dayFilter: string, metrics: string): Observable<IReportDeepResponse> {
    if (!dayFilter) {
      const [start, end] = this.dateTimeFnsService.dateRanges['Last 30 Days'].map((time) => format(time, DATE_FNS_FORMAT));
      dayFilter = `?start_date=${start}&end_date=${end}`;
    }
    const params = { metrics: metrics };
    return this.http.get<IReportDeepResponse>(AppService.getAppUri(`/pwa/overview/${siteId}/report_deep_analytics/${dayFilter}`), {
      params,
    });
  }

  public getOverviewA2HSDevice(siteId: number, deviceId: number): Observable<A2HSPWA> {
    return this.http.get<A2HSPWA>(AppService.getAppUri(`/pwa/overview/${siteId}/a2hs/${deviceId}/`));
  }

  public createA2HS(siteId: string | number, A2HSPWAdata: A2HSPWA): Observable<A2HSPWA> {
    const url = `/pwa/overview/${siteId}/a2hs/`;
    return this.http.post<A2HSPWA>(AppService.getAppUri(url), A2HSPWAdata);
  }

  public updateA2HS(siteId: string | number, A2HSPWAdata: A2HSPWA, id: number): Observable<A2HSPWA> {
    const url = `/pwa/overview/${siteId}/a2hs/${id}/`;
    return this.http.put<A2HSPWA>(AppService.getAppUri(url), A2HSPWAdata);
  }

  public updateIconA2HS(siteId: string | number, id: number, formData: FormData): Observable<A2HSPWA> {
    const url = `/pwa/overview/${siteId}/a2hs/${id}/update_card_icon/`;
    return this.http.patch<A2HSPWA>(AppService.getAppUri(url), formData);
  }

  public getOverviewNotificationPromptDevice(siteId: number, deviceId: number): Observable<A2HSPWA> {
    return this.http.get<A2HSPWA>(AppService.getAppUri(`/pwa/overview/${siteId}/notification_prompt/${deviceId}/`));
  }

  public createNotificationPrompt(siteId: string | number, A2HSPWAdata: A2HSPWA): Observable<A2HSPWA> {
    const url = `/pwa/overview/${siteId}/notification_prompt/`;
    return this.http.post<A2HSPWA>(AppService.getAppUri(url), A2HSPWAdata);
  }

  public updateNotificationPrompt(siteId: string | number, A2HSPWAdata: A2HSPWA, id: number): Observable<A2HSPWA> {
    const url = `/pwa/overview/${siteId}/notification_prompt/${id}/`;
    return this.http.put<A2HSPWA>(AppService.getAppUri(url), A2HSPWAdata);
  }

  public updateIconNotificationPrompt(siteId: string | number, id: number, formData: FormData): Observable<A2HSPWA> {
    const url = `/pwa/overview/${siteId}/notification_prompt/${id}/update_card_icon/`;
    return this.http.patch<A2HSPWA>(AppService.getAppUri(url), formData);
  }

  public getBillingPlans(siteId: number): Observable<ResponseBillingPlans> {
    const url = `/pwa/billing/${siteId}/`;
    return this.http.get<ResponseBillingPlans>(AppService.getAppUri(url));
  }

  public startBillingPlan(siteId: number, plan: BillingPlans): Observable<any> {
    const url = `/pwa/billing/${siteId}/`;
    const data = {
      plan: plan,
      site: {
        id: siteId,
      },
    };
    return this.http.put(AppService.getAppUri(url), data);
  }

  public getUpgradeConfirmUrl(siteId: number): Observable<any> {
    const url = `/pwa/billing/${siteId}/upgrade/`;

    return this.http.get(AppService.getAppUri(url));
  }

  public getContentURL(siteID: number, url: string): Observable<ContentURLPWA> {
    return this.http.post<ContentURLPWA>(AppService.getAppUri(`/pwa/overview/${siteID}/push_notification/url_content/`), { url });
  }

  public getImageFromUrl(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob' });
  }

  public getNumberOfTargetedUsers(siteId: number, targetId: number): Observable<{ count: number }> {
    const url = AppService.getAppUri(`/pwa/overview/${siteId}/target/${targetId}/subscriber_count/`);

    return this.http.get<{ count: number }>(url);
  }

  public downloadReport(id: number, params: Partial<{ start: string; end: string; format: 'csv' | 'tsv' | 'xlsx' }>) {
    const query = new URLSearchParams({
      start_date: params.start ?? '',
      end_date: params.end ?? '',
      download_format: params.format ?? 'csv',
    }).toString();
    const url = Array.of(`/pwa/download/${id}/`, query).join('?');
    return this.fileDownloadService.execDownload(AppService.getAppUri(url)).pipe(
      map((resp) => ({
        name: this.fileDownloadService.getFileNameFromRequest(resp),
        file: resp.body,
      }))
    );
  }

  downloadPushNotification(siteId: number, format: 'csv' | 'tsv' | 'xlsx' = 'csv') {
    return this.fileDownloadService
      .execDownload(AppService.getAppUri(`/pwa/download-push-notification/${siteId}?download_format=${format}`))
      .pipe(
        map((resp) => ({
          file: resp.body,
          name: this.fileDownloadService.getFileNameFromRequest(resp),
        }))
      );
  }
}
